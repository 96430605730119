import React from 'react';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { Button } from 'wix-ui-tpa/Button';
import { st, classes } from './Popup.st.css';
import { PopupEnum } from '../../../../types/common';

interface Props {
  popup: PopupEnum;
  hidePopup: () => void;
}

const Popup: React.FC<Props> = ({ hidePopup, popup }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    switch (popup) {
      case PopupEnum.checkoutPreview:
        return (
          <div className={classes.box}>
            <h2>{t('pp.popups.purchase-in-preview-mode-header')}</h2>
            <p>{t('pp.popups.purchase-in-preview-mode')}</p>
            <Button
              data-hook="PackagePicker-popup-cta"
              onClick={() => {
                hidePopup();
              }}
              className={st(classes.button)}
            >
              {t('pp.popups.purchase-in-preview-mode-cta')}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.wrapper} data-hook="PackagePicker-popup">
      {renderContent()}
    </div>
  );
};

export default Popup;
