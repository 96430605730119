import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useEnvironment, useExperiments } from 'yoshi-flow-editor-runtime';
import { useStylesParams } from '../../../../../hooks';

import { st, classes, vars } from './index.st.css';
import { Cta } from './Cta';
import { Ribbon } from './Ribbon';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { Recurrence } from './Recurrence';
import { Tagline } from './Tagline';
import { Validity } from './Validity';
import { FreeTrial } from './FreeTrial';
import { Footer } from './Footer';
import settingsParams from '../../../settingsParams';
import { EXPERIMENTS } from '../../../../../constants';
import { textAlignmentName } from '../../../../../utils/textAlignment';

export interface PlanProps {
  plan: PublicPlan;
  recurringPlansExist: boolean;
  freeTrialDaysExist: boolean;
  taglinesExist: boolean;
  validityCyclesExist: boolean;
  highlighted: boolean;
  anyBenefits?: boolean;
  onClick: () => void;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  recurringPlansExist,
  freeTrialDaysExist,
  validityCyclesExist,
  taglinesExist,
  highlighted,
  onClick,
  anyBenefits,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const hasTwoDetails = validityCyclesExist && freeTrialDaysExist;
  const blendBgColors = !settings.get(settingsParams.wereNewSettingsOpened);
  const areImprovedAppSettingsEnabled = experiments.enabled(EXPERIMENTS.IMPROVED_APP_SETTINGS_VIEWER);
  const descriptionAlignment = areImprovedAppSettingsEnabled
    ? textAlignmentName(styles.get(stylesParams.descriptionAlignment))
    : textAlignmentName(styles.get(stylesParams.textAlignment));
  const showShadowParam = highlighted ? 'showHighlightedPlanCardShadow' : 'showRegularPlanCardShadow';
  const shadowDistanceParam = highlighted ? 'highlightedCardShadowDistance' : 'regularCardShadowDistance';
  const shadowAngleParam = highlighted ? 'highlightedCardShadowAngle' : 'regularCardShadowAngle';
  const shadowDistance = styles.get(stylesParams[shadowDistanceParam]);
  const showShadow = areImprovedAppSettingsEnabled && settings.get(settingsParams[showShadowParam]);
  const shadowAngle = styles.get(stylesParams[shadowAngleParam]);
  const shadowXOffset = shadowDistance * Math.sin((Math.PI * 2 * shadowAngle) / 360);
  const shadowYOffset = -shadowDistance * Math.cos((Math.PI * 2 * shadowAngle) / 360);
  return (
    <div
      className={st(classes.planWrapper, {
        showShadow,
        highlighted,
        borderRadiusEnabled: areImprovedAppSettingsEnabled,
      })}
      style={{ [vars.shadowXOffset]: shadowXOffset + 'px', [vars.shadowYOffset]: shadowYOffset + 'px' }}
    >
      <Ribbon highlighted={highlighted} />
      <div
        className={st(classes.plan, {
          highlighted,
          blendBgColors,
          borderRadiusEnabled: areImprovedAppSettingsEnabled,
        })}
      >
        <div className={st(classes.planTop, { textAlignment: descriptionAlignment })}>
          <PlanName highlighted={highlighted} name={plan.details?.name || ''} />
          <Price highlighted={highlighted} price={plan.paymentOptions?.price} />
          <Recurrence
            highlighted={highlighted}
            paymentOptions={plan.paymentOptions}
            recurringPlansExist={recurringPlansExist}
          />
          <Tagline
            taglinesExist={taglinesExist}
            highlighted={highlighted}
            description={plan.details?.description || ''}
          />
          <div className={st(classes.duration, { hasTwoDetails, mobile: isMobile })}>
            <Validity highlighted={highlighted} plan={plan} />
            <FreeTrial highlighted={highlighted} plan={plan} freeTrialDaysExist={freeTrialDaysExist} />
          </div>
          <div className={classes.ctaWrapper}>
            <Cta plan={plan} highlighted={highlighted} onClick={onClick} />
          </div>
        </div>
        <Footer benefits={plan.details?.benefits ?? []} anyBenefits={anyBenefits} highlighted={highlighted} />
      </div>
    </div>
  );
};
