import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { NO_BREAK_SPACE } from '../constants';

export const getPlanValidityCycle = (plan: PublicPlan, t: any) => {
  if (!plan.paymentOptions) {
    return NO_BREAK_SPACE;
  }

  const forever = !!plan.paymentOptions.validFor?.forever;
  const period = plan.paymentOptions.validFor?.period;
  const free = plan.paymentOptions.price?.amount === '0';
  const amount = plan.paymentOptions?.validFor?.period?.amount || 0;

  if (forever && free) {
    return t('pp.plan-validity.free-plan');
  }

  if (!period) {
    return NO_BREAK_SPACE;
  }

  if (!forever) {
    switch (period.unit) {
      case PeriodUnit.DAY:
        return period.amount! > 1
          ? t('pp.plan-validity.valid-for-days', { amount })
          : t('pp.plan-validity.valid-for-one-day', { amount });
      case PeriodUnit.WEEK:
        return period.amount! > 1
          ? t('pp.plan-validity.valid-for-weeks', { amount })
          : t('pp.plan-validity.valid-for-one-week', { amount });
      case PeriodUnit.YEAR:
        return period.amount! > 1
          ? t('pp.plan-validity.valid-for-years', { amount })
          : t('pp.plan-validity.valid-for-one-year', { amount });
      default:
        return period.amount! > 1
          ? t('pp.plan-validity.valid-for-months', { amount })
          : t('pp.plan-validity.valid-for-one-month', { amount });
    }
  }

  return NO_BREAK_SPACE;
};
