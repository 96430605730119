import React from 'react';
import { Money } from '@wix/ambassador-pricing-plans-read-api/http';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { useEnvironment } from 'yoshi-flow-editor-runtime';

import { st, classes } from './Price.st.css';

interface Props {
  price?: Money;
  highlighted: boolean;
}

export const Price: React.FC<Props> = ({ price, highlighted }) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={st(classes.wrapper, { highlighted, mobile: isMobile })}>
      <span className={classes.currency}>{currencyToSymbol(price?.currency)}</span>
      <span className={classes.price}>{formatPrice(price)}</span>
    </div>
  );
};

function formatPrice(price?: Money) {
  const [amount, fraction] = price?.amount?.toString().split('.') ?? [];
  if (fraction && fraction.length) {
    return amount + '.' + fraction + (fraction.length > 1 ? '' : '0');
  }
  return amount;
}

export function currencyToSymbol(code?: string): string {
  if (typeof code === 'string') {
    return currencyToSymbolMap[code.toUpperCase()] || code;
  }
  return '';
}
