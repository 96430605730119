
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/4f07f1e3e5a0ce96/membership/pricing-plans-tpa/src/components/PlanList/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/4f07f1e3e5a0ce96/membership/pricing-plans-tpa/src/components/PlanList/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://fad285bce0ab4ed7a6477b438ece90e8@sentry.wixpress.com/432',
      id: 'fad285bce0ab4ed7a6477b438ece90e8',
      projectName: 'pricing-plans-tpa',
      teamName: 'pricing-plans',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'PlanList'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
