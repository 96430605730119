import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import difference from 'lodash/difference';
import { PlansQuery } from '../../services';
import { getIds } from '../../utils/plan';
import { DefaultSettingsAdapter } from '../PackagePicker/DefaultSettingsAdapter';

/**
 * This class overrides adapter settings which handle plan visibility.
 * PlanList widget handles it differently than
 * the main PackagePicker component - rather than
 * explicitly specifying hidden plans ('hiddenPlans' parameter),
 * it specifies visible ones ('visiblePlans' parameter).
 * Because of this, newly created plans are not
 * visible in the widget, until user specifies them as visible.
 *
 * When PlanList widget is created, it will still use 'hiddenPlans' paremeter
 * until migration is run (which happens on opening PlanList widget's plan settings)
 */
export class PlanListSettingsAdapter extends DefaultSettingsAdapter {
  /**
   * This method can be called before migration
   * to 'visiblePlans' parameter is done. If that's
   * the case, parent class method implementation is used
   */
  getHiddenPlanIds(plans: PublicPlan[]): string[] {
    if (this.usesVisiblePlansParameter()) {
      return difference(getIds(plans), this.asArray('visiblePlans'));
    }

    return super.getHiddenPlanIds(plans);
  }

  hidePlan(id: string) {
    this.removeFromVisiblePlans(id);
  }

  unhidePlan(id: string) {
    this.addToVisiblePlans(id);
  }

  runMigrations(plans: PublicPlan[]) {
    this.migrateToVisiblePlansParameter(plans);
  }

  shouldFetchVisiblePlans(loadedPlans: PublicPlan[]) {
    const loadedIds = getIds(loadedPlans);
    return this.asArray('visiblePlans').some((id) => !loadedIds.includes(id));
  }

  getVisiblePlansQuery(): PlansQuery {
    return { include: this.asArray('visiblePlans') };
  }

  private usesVisiblePlansParameter(): boolean {
    return this.get('useVisiblePlans') || this.asArray('visiblePlans').length > 0;
  }

  private addToVisiblePlans(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.concat(id).join(','));
  }

  private removeFromVisiblePlans(id: string) {
    const visiblePlans = this.asArray('visiblePlans');
    this.set('visiblePlans', visiblePlans.filter((visibleId) => visibleId !== id).join(','));
  }

  private migrateToVisiblePlansParameter(plans: PublicPlan[]) {
    if (!this.usesVisiblePlansParameter()) {
      const visiblePlans = difference(getIds(plans), this.asArray('hiddenPlans'));
      this.set('visiblePlans', visiblePlans.join(','));
      this.set('hiddenPlans', '');
    }

    this.set('useVisiblePlans', true);
  }
}
