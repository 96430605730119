import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { Button } from 'wix-ui-tpa/Button';
import { useSettings, useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { useTranslation, useEnvironment } from 'yoshi-flow-editor-runtime';

import { ButtonStyle } from '../../../settingsParams';
import { st, classes } from './Cta.st.css';
import stylesParams from '../../../stylesParams';
import { useSettingsParams } from '../../../../../hooks';

interface Props {
  plan: PublicPlan;
  highlighted: boolean;
  onClick: () => void;
}

enum ButtonStyleStylableFlag {
  emptyCornered = 'emptyCornered',
  emptyRounded = 'emptyRounded',
  fullCornered = 'fullCornered',
  fullRounded = 'fullRounded',
}

export const Cta: React.FC<Props> = ({ highlighted, plan, onClick }) => {
  const settingsParams = useSettingsParams();
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const hasFreeTrial = !!plan.paymentOptions?.freeTrialDays;

  const renderText = () => {
    if (hasFreeTrial) {
      return settings.get(settingsParams.freeTrialButtonText) || t('pp.defaults.freeTrialButton');
    }

    return settings.get(settingsParams.buttonText) || t('pp.defaults.button');
  };

  return (
    <Button
      data-hook="plan-cta"
      onClick={onClick}
      className={st(classes.root, {
        highlighted,
        mobile: isMobile,
        style: buttonStyleToStylableFlag(styles.get(stylesParams.buttonStyle)),
      })}
    >
      {renderText()}
    </Button>
  );
};

function buttonStyleToStylableFlag(buttonStyle: ButtonStyle): ButtonStyleStylableFlag {
  switch (buttonStyle) {
    case ButtonStyle.EMPTY_CORNERED:
      return ButtonStyleStylableFlag.emptyCornered;
    case ButtonStyle.EMPTY_ROUNDED:
      return ButtonStyleStylableFlag.emptyRounded;
    case ButtonStyle.FULL_ROUNDED:
      return ButtonStyleStylableFlag.fullRounded;
    default:
    case ButtonStyle.FULL_CORNERED:
      return ButtonStyleStylableFlag.fullCornered;
  }
}
